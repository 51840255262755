import React, { Component } from 'react';
import Link from '../utils/link';
import { LogoStacked, Close, Tree } from '../components/icons';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class Form extends Component {
  state = {
    result: '',
    msg: '',
    email: '',
    'studio-ashby-newsletter': false,
    'sister-newsletter': false,
    subscribe: false,
  };

  handleChange = e => {
    const { type, name, checked, value } = e.target;
    this.setState({ [name]: type === 'checkbox' ? checked : value });
  };

  handleSubmit = e => {
    e.preventDefault();

    if (!this.refs.form.checkValidity()) return false;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => {
        this.setState({ result: "success" });
      })
      .catch(error => this.setState({ result: "fail", msg: error }));
  };

  render() {
    let props = {
      ref: "form",
      name: "Studio Ashby Newsletter Signup",
      className: "form",
      onSubmit: this.handleSubmit,
      "data-netlify": "true",
      "data-netlify-honeypot": "bot-field",
    };

    if (this.state.result === "success")
      return (
        <p className="popup__success">
          Thanks for subscribing.
        </p>
      );

    return (
      <form netlify {...props}>
        <div className="row">
          <div className="col newsletter-signup-checkboxes">
            <div className="checkbox">
              <input
                type="checkbox"
                name="studio-ashby-newsletter"
                id="studio-ashby-newsletter"
                onChange={this.handleChange}
                required
              />
              <label className='small' htmlFor='studio-ashby-newsletter'>
                Studio Ashby
              </label>
            </div>

            <div className="checkbox">
              <input
                type="checkbox"
                name="sister-newsletter"
                id="sister-newsletter"
                onChange={this.handleChange}
                required
              />
              <label className='small' htmlFor='sister-newsletter'>
                Sister
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className='col__details'>
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                onChange={this.handleChange}
                required
              />
            </div>
          </div>

          <div className="col">
            <div className="checkbox">
              <input
                type="checkbox"
                name="subscribe"
                id="subscribe"
                required
                onChange={this.handleChange}
              />
              <label htmlFor='subscribe'>
                I consent to the <Link to='/legal/'>Terms & Conditions</Link>
              </label>
            </div>
          </div>

          <div className="col">
            <div className="btn-container">
              <button type="submit" className="btn">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

class NewsletterPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {
    if (!sessionStorage.getItem('newsletterModalShown')) {
      this.modalTimeout = setTimeout(() => {
        this.setState({ showModal: true });
      }, 10000);
    }
  }

  componentWillUnmount() {
    if (this.modalTimeout) {
      clearTimeout(this.modalTimeout);
    }
  }

  render() {
    const { show, hidePopup } = this.props;

    if (!show) return null; // Do not render if `show` is false

    return (
      <div className="modal-overlay active ">
        <div className="modal">
          <button
            type='button'
            className='modal__close'
            onClick={hidePopup} // Use `hidePopup` directly
          >
            <Close color={'#633B2F'} />
          </button>
          <div className="modal__content">
            <div className='modal__header'>
              <div className='modal__logo'>
                <Tree color='#633B2F' />
              </div>
              <h3>Sign up to our Newsletters</h3>
            </div>
            <div className='modal__form'>
              <Form hidePopup={hidePopup} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewsletterPopup;

